import React, { useState, useEffect } from "react";
import "./EditTasks.css";
import WebApp from "@twa-dev/sdk";
import axios from 'axios';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB'; 
registerLocale('en-GB', enGB);

const EditTasks = () => {
  const { taskId: paramTaskId } = useParams(); 
  const location = useLocation();
  const navigate = useNavigate();

  const taskId = location.state?.taskId || paramTaskId;

  const [hasChanges, setHasChanges] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);  

  const [task, setTask] = useState(null);
  const [user, setUser] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDeadline, setIsEditingDeadline] = useState(false);
  const [newName, setNewName] = useState('');
  const [newDeadline, setNewDeadline] = useState(null);
  const [selectedProject, setSelectedProject] = useState(''); 
  const [selectedExecutor, setSelectedExecutor] = useState('');
  const [selectedExecutorId, setSelectedExecutorId] = useState("");
  const [chats, setChats] = useState([]);
  const [members, setMembers] = useState([]);
  const [chatName, setChatName] = useState('Unnamed Chat');
  
  const apiUrl = process.env.NODE_ENV === 'production' 
    ? 'https://test.tasks.ledokol.it/api2' 
    : 'http://localhost:5001';

  const checkIfEdited = () => {
    if (!isInitialLoad && (newName !== task.name || newDeadline?.toISOString() !== new Date(task.deadline)?.toISOString() || selectedProject !== task.chat_id || selectedExecutor !== task.executor)) {
      setHasChanges(true); 
    } else {
      setHasChanges(false);
    }
  };

  const fetchUserChats = async () => {
    if (!user.user_id) return;
    try {
      const response = await fetch(`${apiUrl}/get-user-chats/?userId=${user.user_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (response.ok) {
        const data = await response.json();
        setChats(data.chats || []);
      } else {
        console.error("Failed to fetch user chats");
      }
    } catch (error) {
      console.error("Error fetching user chats:", error);
    }
  };

  const fetchTask = async () => {
    if (!taskId) {
      console.error('Task ID is undefined');
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/get-task/${taskId}`);
      const taskData = response.data;
      setTask(taskData);
      setNewName(taskData.name);
      setNewDeadline(taskData.deadline ? new Date(taskData.deadline) : null); 
      setSelectedProject(taskData.chat_id); 
      setSelectedExecutor(taskData.executor);

      const currentChat = chats.find(chat => chat.id === taskData.chat_id);
      setChatName(currentChat ? currentChat.name : 'Unnamed Chat');

      setIsInitialLoad(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn('Task not found, setting default task');
        setTask({
          id: taskId,
          name: 'Default Task Name',
          description: 'No description provided',
          deadline: null,
          chat_id: 'No',
        });
        setChatName('Unnamed Chat');
        setIsInitialLoad(false); 
      } else {
        console.error('Error fetching the task:', error);
      }
    }
  };

  useEffect(() => {
    const tg = WebApp;
    tg.ready();

    const initUserAndData = async () => {
      // Загружаем пользователя
      const userId = tg.initDataUnsafe?.user?.id;
      setUser({
        username: tg.initDataUnsafe?.user?.username || "Username",
        firstName: tg.initDataUnsafe?.user?.first_name || "User",
        user_id: userId,
      });

      // Загружаем чаты пользователя
      await fetchUserChats();

      // Загружаем данные задачи
      await fetchTask();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        if (task?.chat_id && chats.length > 0) {
          const chat = chats.find(chat => chat.id === task?.chat_id);
          if (chat) {
            navigate(`/chat/${task?.chat_id}`, { state: { chatId: task?.chat_id, chatName: chat.name || 'Unnamed Chat' } });
          } else {
            navigate('/');
          }
        } else {
          navigate('/');
        }
      });
    };

    if (!task) {
      initUserAndData();
    }
  }, [taskId]); // Обновляем только по taskId

  useEffect(() => {
    if (selectedProject) {
      const fetchMembers = async () => {
        try {
          const response = await axios.get(`${apiUrl}/get-chat-members?chatId=${selectedProject}`);
          setMembers(response.data.members || []); 
        } catch (error) {
          console.error('Error fetching chat members:', error);
        }
      };

      fetchMembers();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (task) {
      checkIfEdited();
    }
  }, [newName, newDeadline, selectedProject, selectedExecutor]);

  const handleSave = async () => {
    if (!hasChanges) return; 
    try {
      const formattedDeadline = newDeadline ? newDeadline.toISOString().split("T")[0] : null;
      const updatedTask = {
        name: newName,
        deadline: formattedDeadline || null, 
        executor: selectedExecutor || null,
        executor_id: selectedExecutorId || null,
        completed: task.completed || false,
        message_id: task.message_id || null,
        chat_id: selectedProject || null,
        user_id: user.user_id,
      };

      await axios.put(`${apiUrl}/update-task/${taskId}`, updatedTask);
      setHasChanges(false);
      console.log("Task updated successfully!");
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const handleDeleteTask = async () => {
    try {
      const response = await fetch(`${apiUrl}/delete-task/${taskId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        navigate(-1); 
        console.log("Task deleted successfully!");
      } else {
        console.error('Failed to delete task');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleAddToChat = () => {
    WebApp.openTelegramLink('https://t.me/task_trackerfj_bot?startgroup=true');
  };

  if (!task) {
    return <div>Loading...</div>;
  }

  return (
    <div className="root">
      <div className="view">
        <div className="frame">
          <div className="div">
            <img className="image" alt="Profile" src={user.profilePhoto} />
            <div className="text-wrapper">{user.username}</div>
          </div>
          <div className="add-to-chat" onClick={handleAddToChat}>
            <img className="img" alt="Star Icon" src="/img/addicon.svg" />
            <div className="frame-2">
              <div className="text-wrapper-2">Add to chat</div>
            </div>
          </div>
        </div>
      </div>

      <div className="groups-2">
        {/* Название таска */}
        <div className="task-container" onClick={() => setIsEditingName(true)}>
          {isEditingName ? (
            <textarea
              type="text"
              className="task-input task-title-edit" 
              rows="1"
              placeholder="Enter task name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={() => setIsEditingName(false)}
              autoFocus
            />
          ) : (
            <div className="task-title">{newName}</div>
          )}
        </div>
      </div>

      <div className="groups-2">
        {/* Дедлайн */}
        <div className="task-edit-container" onClick={() => setIsEditingDeadline(true)}>
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/CalendarActiveIcon.svg" alt="Calendar Icon" />
            <div className="task-label">Deadline</div>
          </div>
          <div className="task-edit-desc">
            {isEditingDeadline ? (
              <DatePicker
                selected={newDeadline}
                onChange={(date) => setNewDeadline(date)}
                locale="en-GB"
                onBlur={() => setIsEditingDeadline(false)}
                dateFormat="dd/MM/yyyy"
                autoFocus
              />
            ) : (
              <div className="task-value">
                {newDeadline ? newDeadline.toLocaleDateString() : 'No date'}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="groups-2">
        {/* Проект */}
        <div className="task-edit-container">
          <div className="task-edit-title">
            <img className="task-icon" src="/icons/FolderIcon.svg" alt="Project Icon" />
            <div className="task-edit-desc">
            {selectedProject ? (
              <div className="task-value">{chats.find(chat => chat.id === selectedProject)?.name || 'No Project'}</div>
            ) : (
              <select 
                className="task-input .project-input"
                value={selectedProject} 
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                <option value="">Select Project</option>
                {chats.length > 0 ? (
                  chats.map((chat) => (
                    <option key={chat.id} value={chat.id}>
                      {chat.name}
                    </option>
                  ))
                ) : (
                  <option value="">No Projects Available</option>
                )}
              </select>
            )}
          </div>
          </div>
        </div>
      </div>

      <div className="groups-2">
        {members.length > 0 && (
          <div className="task-edit-container">
            <div className="task-edit-title">
              <img className="task-icon" src="/icons/Executor.svg" alt="User Icon" />
              <div className="task-label">Executor</div>
            </div>
            <div className="task-edit-desc">
            <select
                className="task-input project-input"
                value={selectedExecutor}
                onChange={(e) => {
                  const selectedMember = members.find(member => member.username === e.target.value);
                  setSelectedExecutor(selectedMember.username); 
                  setSelectedExecutorId(selectedMember.id); 
                }}
              >
                <option value="">Select Executor</option>
                {members.map((member) => (
                  <option key={member.id} value={member.username}>
                    {member.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      {/* Кнопка будет активна только если есть изменения */}
      <div 
        className={`save-button ${hasChanges ? 'active' : 'disabled'}`} 
        onClick={hasChanges ? handleSave : null}
      >
        Сохранить
      </div>
      <div 
        className="delete-button" 
        onClick={handleDeleteTask}
      >
        Delete task
      </div>
    </div>
  );
};

export default EditTasks;
